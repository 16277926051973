import React from 'react';
import LoginView from './views/LoginView';
import AdminView from './views/AdminView';
import { Backdrop, CircularProgress, ThemeProvider } from '@material-ui/core';
import theme from './theme';
import { useAuthState, AuthProvider } from './context/auth';

function AuthenticatedApp() {
  
  const authState = useAuthState();

  if (authState.loading) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (authState.authenticated ?
    <AdminView /> :
    <LoginView />)
}

function App() {
  

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <AuthenticatedApp />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;


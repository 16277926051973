import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Snackbar,
  Card
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useAuthState } from '../context/auth';

const LoginView = () => {

  const authState = useAuthState();
  const [snackAlert, setSnackAlert] = React.useState({ open: false, text: '' });

  const submit = ({ token }) => {
    authState.setToken(token);
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackAlert({ open: false, text: '' });
  }

  return (
    <div style={{height: '100%'}}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackAlert.open}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert
          severity='error'
          color='error'
          elevation={6}
          variant='filled'
          onClose={handleCloseSnack}
        >{snackAlert.text}</Alert>
      </Snackbar>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Card style={{padding: 25, marginTop: '50%'}}>
            <Formik
              initialValues={{
                token: ''
              }}
              validationSchema={Yup.object().shape({
                token: Yup.string().max(255).required('A valid API token is required')
              })}
              onSubmit={submit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                  <form onSubmit={handleSubmit}>
                    <Box mb={3}>
                      <Typography
                        color="textPrimary"
                        variant="h2"
                      >
                        Accedi
                  </Typography>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                      >
                        Accedi al pannello Admin con un API token
                  </Typography>
                    </Box>
                    <TextField
                      error={Boolean(touched.token && errors.token)}
                      fullWidth
                      helperText={touched.token && errors.token}
                      label="API Token"
                      margin="normal"
                      name="token"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="string"
                      value={values.token}
                      variant="outlined"
                    />
                    <Box my={2}>
                      <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Accedi
                  </Button>
                    </Box>
                  </form>
                )}
            </Formik>
          </Card>
        </Container>
      </Box>
    </div>
  );
};

export default LoginView;

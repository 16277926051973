import React, { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import SendIcon from '@material-ui/icons/Send';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomSnackbar from '../components/CustomSnackbar';
import {
  createOrganization,
  createUser,
  deleteUser,
  getOrganizations,
  getUsers,
  sendEmail,
  EmptyPagination
} from '../HttpRequests/HttpRequests';

function App() {

  const [organizations, setOrganizations] = useState(EmptyPagination);
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [email, setEmail] = useState([]);
  const [wrongEmailFormat, setWrongEmailFormat] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [error, setError] = useState(false);
  const [message, setMessage] = useState({ open: false, text: '', type: 'info' });
  const [searchString, setSearchString] = useState('');

  const fetchOrganizations = async () => {
    try {
      const { data } = await getOrganizations();
      setOrganizations(data);
    } catch (err) {
      setMessage({ open: true, text: err.message, type: 'error' });
    }
  }

  const fetchUsers = async (page, limit, filter) => {
    try {
      const { data } = await getUsers(page, limit, filter);
      setUsersCount(parseInt(data.totalDocs, 10));
      setUsers(data.docs);
    } catch (err) {
      setMessage({ open: true, text: err.message, type: 'error' });
    }
  }

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    fetchUsers(page + 1, limit, searchString);
  }, [page, limit, searchString]);

  const handleInviteUser = async () => {
    const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    if (!emailRegExp.test(email)) {
      setWrongEmailFormat(true);
      setError(true);
      setMessage({ open: true, text: 'Dati non corretti!', type: 'error' });
      return;
    }
    if (selectedOrg === '') {
      setError(true);
      setMessage({ open: true, text: 'Inserisci i dati mancanti!', type: 'error' });
      return;
    }
    try {
      await createUser(selectedOrg, email);
      setMessage({ open: true, text: 'Invito spedito con successo!', type: 'success' });
      setSelectedOrg('');
      setEmail('');
      setError(false);
      fetchUsers(page + 1, limit, searchString);
    } catch (err) {
      setMessage({ open: true, text: err.message, type: 'error' })
    }
  }

  const handleDeleteUser = async uid => {
    try {
      await deleteUser(uid);
      setMessage({ open: true, text: 'Utente cancellato con successo!', type: 'success' });
      fetchUsers(page + 1, limit, searchString);
    } catch (err) {
      setMessage({ open: true, text: err.message, type: 'error' });
    }
  }

  const handleSendEmail = async (email) => {
    try {
      await sendEmail(email)
      setMessage({ open: true, text: 'Email spedita!', type: 'success' })
    } catch (err) {
      setMessage({ open: true, text: err.message, type: 'error' })
    }
  }

  const handleAddOrganization = async () => {
    if (orgName === '')
      setMessage({ open: true, text: 'Devi impostare un nome!', type: 'error' });
    else {
      try {
        await createOrganization(orgName);
        fetchOrganizations();
        setMessage({ open: true, text: 'Organizzazione aggiunta con successo!', type: 'success' });
        setOrgName('')
      } catch (err) {
        setMessage({ open: true, text: err.message, type: 'error' });
      }
    }
  }

  return (
    <Container style={{ paddingTop: 16 }}>
      <AppBar>
        <Box p={1} pl={3}>
          <img
            alt="Logo"
            width="50"
            src="/logo.svg"
          />
        </Box>
      </AppBar>
      <Toolbar />
      <Box>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">Invita Utente</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item sm={12} md={4}>
                <TextField
                  select
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  label="Organizzazione"
                  placeholder="Organizzazione"
                  value={selectedOrg}
                  onChange={e => setSelectedOrg(e.target.value)}
                  error={error && selectedOrg === ''}
                  helperText={error && selectedOrg === '' ? 'devi selezionare una organizzazione!' : undefined}
                >
                  {organizations.docs.map(org => (
                    <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={12} md={4}>
                <TextField
                  label='Email' variant='outlined'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  type='email'
                  error={error && wrongEmailFormat}
                  helperText={error && wrongEmailFormat ? 'email non valida!' : undefined}
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleInviteUser}
                  style={{ marginTop: 15 }}
                >
                  Invita
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={2}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">Organizzazioni</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <Grid container spacing={1} justify="center" alignItems='center'>
              <Grid item xs={12} md={6}>
                <TextField
                  label='Nome Organizzazione'
                  variant='outlined'
                  value={orgName}
                  onChange={e => setOrgName(e.target.value)}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={handleAddOrganization}
                        >Aggiungi</Button>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
                  {organizations.docs.map(org => (
                    <Box p={1} key={org.id}>
                      <Chip label={org.name} />
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Card style={{ marginTop: 16 }}>
        <CardContent>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon
                    fontSize="small"
                    color="action"
                  >
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton onClick={() => fetchUsers(page + 1, limit, searchString)}>
                  <SvgIcon
                    fontSize="small"
                    color="action"
                  >
                    <RefreshIcon />
                  </SvgIcon>
                </IconButton>
              )
            }}
            placeholder="Cerca utente"
            variant="outlined"
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
          />
        </CardContent>
      </Card>

      <Card style={{ marginTop: 16 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Stato</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Nome</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Organizzazione</TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                  <Chip
                    color={user.isPendingActivation ? 'default' : 'primary'}
                    size="small"
                    label={user.isPendingActivation ? 'Pending' : 'Attivo'}
                  />
                </TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.organization.name}</TableCell>
                <TableCell align='center' padding="checkbox">
                  <IconButton style={{ color: '#dc3545' }} onClick={() => handleDeleteUser(user.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
                <TableCell align='center' padding="checkbox">
                  {user.isPendingActivation ? (
                    <Chip
                      style={{ backgroundColor: '#757ce8', color: '#fff' }}
                      size="small"
                      label="Rinvita"
                      clickable
                      onDelete={() => handleSendEmail(user.email)}
                      deleteIcon={<SendIcon style={{ color: '#fff' }} />}
                      onClick={() => handleSendEmail(user.email)}
                    />
                  ) : undefined}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component='div'
          count={usersCount}
          rowsPerPage={limit}
          page={page}
          rowsPerPageOptions={[5, 10, 25]}
          onChangePage={(e, page) => setPage(page)}
          labelRowsPerPage="Righe per pagina"
          onChangeRowsPerPage={(e) => {
            setLimit(parseInt(e.target.value, 10));
            setPage(0);
          }}
        />
      </Card>
      {/*<AppBar position='static'>
        <Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v)}>
          <Tab label='utenti' id='utenti' aria-controls='utenti' />
          <Tab label='pending' id='pending' aria-controls='pending' />
          <Tab label='organizzazioni' id='organizzazioni' aria-controls='organizzazioni' />
        </Tabs>
      </AppBar>

      <div role="tabpanel" hidden={selectedTab !== 0} id='utenti' aria-labelledby='utenti'>
        <UsersTab
          organizations={organizations}
          setOrganizations={setOrganizations}
        />
      </div>
      <div role="tabpanel" hidden={selectedTab !== 1} id='pending' aria-labelledby='pending'>
        <PendingTab />
      </div>
      <div role="tabpanel" hidden={selectedTab !== 2} id='organizzazioni' aria-labelledby='organizzazioni'>
        <OrganizationsTab
          organizations={organizations}
          setOrganizations={setOrganizations}
        />
      </div>*/}
      <CustomSnackbar
        open={message.open}
        text={message.text}
        type={message.type}
        setter={setMessage}
      />
    </Container>
  );
}

export default App;
import React from 'react';
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const CustomSnackbar = ({ open, text, type, setter }) => {

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setter({ open: false, text: '', type: type });
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleCloseMessage}
    >
      <Alert
        severity={type}
        color={type}
        elevation={6}
        variant='filled'
        onClose={handleCloseMessage}
      >{text}</Alert>
    </Snackbar>
  );
}

export default CustomSnackbar;
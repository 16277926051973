import React, { useContext, useState, useEffect, createContext } from "react";
import { checkAuth } from '../HttpRequests/HttpRequests';
import PropTypes from 'prop-types';

const AuthStateContext = createContext();

export function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within an AuthProvider.');
  }
  return context;
}

export const getToken = () => {
  return localStorage.getItem("auth_token");
}

const AuthProvider = ({ children }) => {

  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [internalToken, setInternalToken] = useState();

  const setToken = (token) => {
    localStorage.setItem("auth_token", token);
    setInternalToken(token);
  }

  useEffect(() => {
    setLoading(true);
    (async() => {
      const authStatus = await checkAuth();

      setAuthenticated(authStatus);
      setLoading(false);
    })();
  }, [internalToken]);

  return (
    <AuthStateContext.Provider value={{
      loading,
      authenticated,
      setToken
    }}>
      {children}
    </AuthStateContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthProvider };
import axios from 'axios';
import * as firebase from 'firebase/app';
import * as escapeStringRegexp from 'escape-string-regexp';
import { getToken } from '../context/auth';
import 'firebase/auth';

const REST_BASE_PATH = process.env.REACT_APP_REST_URL || 'http://localhost:3004/';

const instance = axios.create({
  baseURL: REST_BASE_PATH,
});

instance.interceptors.request.use(async (config) => {
  const token = getToken();
  config.headers['x-api-key'] = token;
  return config;
});

let emailUrl = 'http://localhost:3000/register';

if (process.env.REACT_APP_PUBLIC_URL !== undefined) {
  let pubUrl = process.env.REACT_APP_PUBLIC_URL;
  if (pubUrl.endsWith('/')) {
    pubUrl = pubUrl.replace(/\/$/, '');
  }
  emailUrl = `${pubUrl}/register`;
}

export const EmptyPagination = {
  docs: [],
  totalDocs: 0,
  limit: 10,
  page: 1,
  totalPages: 1,
};

export const checkAuth = async () => {
  try {
    const request = await instance.get(
      '/checkAuth'
    );

    if (request.status === 200 && request.data === "OK") {
      return true;
    }
  } catch (err) {
  }

  return false;
}

export const getUsers = async (page = 1, limit = 10, userFilter = undefined) => {
  const where = {};
  if (typeof userFilter === 'string' && userFilter.length > 0) {
    const matchString = `.*${escapeStringRegexp(userFilter)}.*`;
    where['or'] = [
      { 
        name: { like: matchString, options: 'i' },
      },
      {
        email: { like: matchString, options: 'i' },
      }
    ]
  }
  return instance.get(
    '/users',
    {
      params: {
        page,
        limit,
        where: JSON.stringify(where),
      }
    }
  );
}

export const getPendingUsers = async (page = 1, limit = 10, userFilter = undefined) => {
  const where = { isPendingActivation: true };
  if (typeof userFilter === 'string' && userFilter.length > 0) {
    const matchString = `.*${escapeStringRegexp(userFilter)}.*`;
    where['or'] = [
      { 
        name: { like: matchString, options: 'i' },
      },
      {
        email: { like: matchString, options: 'i' },
      }
    ]
  }
  return instance.get(
    '/users',
    {
      params: {
        page,
        limit,
        where: JSON.stringify(where),
      }
    }
  );
}

export const createUser = (organizationId, email) => {
  const user = {
    email: email,
    isPendingActivation: true,
    organizationId: organizationId
  };
  return new Promise((resolve, reject) => {
    instance.post(`/users`, user)
    .then(() => {
      sendEmail(email)
        .then((val) => resolve(val))
        .catch((err) => reject(err.message));
    })
    .catch((err) => reject(`Server Error ${err}`));
  });
  
}

export const deleteUser = (uid) => {
  return instance.delete(`/users/${uid}`);
}

export const getOrganizations = () => {
  return instance.get(`/organizations`);
}

export const createOrganization = (name) => {
  const data = {name: name};
  return instance.post(`/organizations`, data);
}


// FIREBASE 

export const sendEmail = (email) => {
  var actionCodeSettings = {
    url: `${emailUrl}?emailSignin=${email}`,
    handleCodeInApp: true,
  };
  return firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
  
}

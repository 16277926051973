import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase/app';

let firebaseConfig;

try {
  firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)
} catch {
  firebaseConfig = {
    apiKey: "AIzaSyC8n8nYdTacgq8UDU1_kf69lu6mc-7yHXc",
    authDomain: "panel-sse.firebaseapp.com",
    databaseURL: "https://panel-sse.firebaseio.com",
    projectId: "panel-sse",
    storageBucket: "panel-sse.appspot.com",
    messagingSenderId: "1003285005591",
    appId: "1:1003285005591:web:e3101a0fb4034b4d06a352"
  };
}

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
